import { memo, useMemo } from 'react';
import { flexRender } from '@tanstack/react-table';
import { useSortable } from '@dnd-kit/sortable';

import { getCommonCellStyles } from '../../util';
import { getCementoStringValue } from '../../../../../utils';
import {
  FILES_ARRAY,
  DRAWINGS_ARRAY,
  PDF,
  PICTURE,
  VIDEO,
  BOOLEAN,
} from '../../../../../../propertiesTypes/propertiesTypes';

import IssueStateCell from './IssueStateCell';
import AttachmentsCell from './AttachmentsCell';
import CheckboxCell from './CheckboxCell';
import Text from '../../../../../../../web/components/CementoComponents/Text';

const CustomCell = ({ cell, className }) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });

  const style = useMemo(() => {
    return getCommonCellStyles({ column: cell.column, isDragging, transform });
  }, [cell.column, isDragging, transform]);

  const { columnId, cementoType, cellValue, cellContent, isSummaryCell, CustomComponent } = useMemo(() => {
    const columnId = cell.column.id;
    const cementoType = cell.column.columnDef.cementoType;
    const cellValue = cell.row.original[columnId];
    const cellContent = getCementoStringValue(cell.row.original[columnId]);
    const isSummaryCell = !!cell.row.original.subRows;

    const CustomComponent = CustomUI[columnId] || CustomUI[cementoType];

    return {
      columnId,
      cementoType,
      cellValue,
      cellContent,
      isSummaryCell,
      CustomComponent,
    };
  }, [cell.column, cell.row]);

  return (
    <td style={style} className={`CustomCell ${className}`} ref={setNodeRef}>
      <div style={{ width: style?.width }}>
        {CustomComponent && !isSummaryCell ? (
          <CustomComponent
            columnId={columnId}
            cementoType={cementoType}
            cellValue={cellValue}
            cellContent={cellContent}
            isSummaryCell={isSummaryCell}
          />
        ) : (
          <Text
            tooltipText={cellContent}
            title={cellContent}
            withTooltip={typeof cellContent === 'string'}
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              height: '100%',
              width: '100%',
              textAlign: 'center',
              lineHeight: '40px',
              padding: isSummaryCell ? 0 : '0 4px',
              cursor: isSummaryCell ? 'auto' : 'pointer',
            }}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Text>
        )}
      </div>
    </td>
  );
};

const CustomUI = {
  'issueState': IssueStateCell,
  [FILES_ARRAY]: AttachmentsCell,
  [DRAWINGS_ARRAY]: AttachmentsCell,
  [PDF]: AttachmentsCell,
  [PICTURE]: AttachmentsCell,
  [VIDEO]: AttachmentsCell,
  [BOOLEAN]: CheckboxCell,
};

export default memo(CustomCell);
