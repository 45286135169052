import './index.css';
import { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { ObjectsWrapperDataContext, ObjectsWrapperManipulationContext } from './ObjectsWrapper';
import FilterBar from './components/FilterBar';
import ObjectsWrapperGridView from './views/grid/ObjectsWrapperGridView';
import ObjectsWrapperListViewTanStackTable from './views/table/TanStackTable/ObjectsWrapperListViewTanStackTable';
import SidebarBuilding from '../../web/layouts/SidebarBuilding';
import useIntl from '../intl/useIntl';
import { useSelector } from 'react-redux';
import { FILTER_URL_KEY } from '../../web/app/constants';
import { encodeFilterToSearch } from '../../web/app/funcs';
import SplitViewPage from '../../web/layouts/SplitViewPage';
import { VIEW_TYPES } from './const';
import LoadingIndicator from '../../web/components/Loading/LoadingIndicator';
import { useLoading } from '../hooks/useLoading';
import { loadingSubjectsMapper } from '../../web/views/Properties/PropertyAnalytics';
import NoItemsIcon from './components/NoItemsIcon';

const ViewsMap = {
  [VIEW_TYPES.GRID]: ObjectsWrapperGridView,
  [VIEW_TYPES.TABLE]: ObjectsWrapperListViewTanStackTable,
};

/**
 *
 * @param {{ viewType?: 'grid' | 'list' }} props
 * @returns
 */
const ObjectsWrapperView = (props) => {
  const { ItemComponent } = props;
  const { data } = useContext(ObjectsWrapperDataContext);
  const { subjectType, selectedObject, viewType, filters, setFilters } = useContext(ObjectsWrapperManipulationContext);

  const intl = useIntl();
  const rtl = useSelector((state) => state.app.rtl);

  const subjectPaths = useMemo(() => {
    return loadingSubjectsMapper(subjectType, props.selectedProjectId);
  }, [subjectType]);

  const { isLoading, progress } = useLoading(subjectPaths);

  const ViewWrapper = useMemo(() => {
    return ViewsMap[viewType];
  }, [viewType]);

  const onLocationChange = (selectedLocation) => {
    setFilters((filters) => {
      const key = `${selectedLocation.type}Id`;
      const locationId = selectedLocation[key];

      const newFilter = {
        ...(filters?.newFilter || {}),
      };

      delete newFilter.building;
      delete newFilter.floor;
      delete newFilter.unit;

      if (locationId !== 'ALL') {
        newFilter[selectedLocation.type] = locationId;
      }

      return {
        ...(filters || {}),
        newFilter,
        newSearch: encodeFilterToSearch(newFilter, location.search, FILTER_URL_KEY),
      };
    });
  };
  // WIP WORK OF SIDE CARD BELOW
  const infoCardMode = 'side';
  const sideCardObject = selectedObject;
  const closeSelectedCell = () => {
    // const { onSideClose } = this.props;
    // this.setState({ selectedCell: null, sideCardObject: null });
    // if (onSideClose) onSideClose();
  };
  return (
    <SplitViewPage
      rtl={rtl}
      onSideClose={closeSelectedCell}
      withHorizontalScroll={true}
      SideStack={Boolean(sideCardObject) && [sideCardObject]}
      sideStackObjectId={sideCardObject?.props?.objectId}
      mode={infoCardMode}
      Main={
        <main key='ObjectsWrapperView' className='ObjectsWrapperView_root'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}>
            <FilterBar />
            <div
              className='ObjectsWrapperView_content'
              style={{
                display: 'flex',
              }}>
              <SidebarBuilding
                navigationParams={filters?.location || {}}
                selectedLocation={filters?.location || {}}
                rtl={rtl}
                intl={intl}
                mainComponentStyle={{ height: '100%' }}
                location={location}
                selectedProjectId={props.selectedProjectId}
                contentType={props.contentType}
                showBadges={true}
                filteredPosts={data}
                onChange={onLocationChange}
              />
              {Object.keys(data).length ? (
                <ViewWrapper ItemComponent={ItemComponent} />
              ) : (
                <NoItemsIcon className='ObjectsWrapperView_empty-icon' />
              )}
            </div>
          </div>
          <div
            className={classNames('ObjectsWrapperView_loading-container', {
              'ObjectsWrapperView_loading-container__visible': isLoading,
            })}>
            <LoadingIndicator loadingProgress={progress} />
          </div>
        </main>
      }
    />
  );
};

export default ObjectsWrapperView;
