import './index.css';
import { useState, useContext, useMemo } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import classNames from 'classnames';
import _ from 'lodash';
import MultiCheckSelect from '../../../../web/components/CementoComponents/MultiCheckSelect';
import { ObjectsWrapperManipulationContext } from '../../ObjectsWrapper';
import useIntl from '../../../intl/useIntl';
import EyeIcon from './EyeIcon';
import theme from '../../../../web/assets/css/theme';

const ColumnVisibilityControls = () => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useDetectClickOutside({ onTriggered: () => setIsOpen(false) });
  const { columnDefinitions, setColumnDefinitions } = useContext(ObjectsWrapperManipulationContext);

  const headerGroups = _.groupBy(columnDefinitions, 'section.id');

  const columnVisibilityOptions = useMemo(() => {
    if (!columnDefinitions?.length) return null;
    return Object.entries(headerGroups).reduce((acc, [groupKey, columnDefinitions], index) => {
      const groupTitle = columnDefinitions[0]?.section?.title?.id
        ? intl.formatMessage(columnDefinitions[0]?.section?.title)
        : columnDefinitions[0]?.section?.getCementoTitle() ?? '-';

      let isSectionVisible = true;
      const groupOptions = columnDefinitions.map((columnDefinition) => {
        const title = columnDefinition?.title?.id
          ? intl.formatMessage(columnDefinition.title)
          : columnDefinition?.getCementoTitle() ?? '-';

        isSectionVisible = isSectionVisible && columnDefinition.isVisible;
        return {
          id: columnDefinition.key,
          label: title,
          checked: columnDefinition.isVisible,
          ordinalNo: index,
          className: 'ColumnVisibilityControls_checkbox',
        };
      });

      acc = [
        ...acc,
        {
          id: groupKey,
          label: groupTitle,
          isSection: true,
          checked: isSectionVisible,
          ordinalNo: index,
          isDisabled: true,
          className: 'ColumnVisibilityControls_section',
        },
        ...groupOptions,
      ];
      return acc;
    }, []);
  }, [columnDefinitions]);

  if (!columnVisibilityOptions?.length) return null;

  const onChange = (checkboxes, checked, unchecked, item) => {
    const isSection = checkboxes.find((checkbox) => !item || checkbox.id === item?.id)?.isSection;
    setColumnDefinitions(
      columnDefinitions.map((columnDefinition) => {
        return {
          ...columnDefinition,
          isVisible: checkboxes.find((item) =>
            [columnDefinition.key, isSection ? columnDefinition?.section?.id : null].includes(item?.id)
          )?.checked,
        };
      })
    );
  };

  return (
    <div className='ColumnVisibilityControls ColumnVisibilityControls_root' ref={ref}>
      <div className='ColumnVisibilityControls_button' onClick={() => setIsOpen((isOpen) => !isOpen)}>
        <span className='ColumnVisibilityControls_button_label'>
          {intl.formatMessage({
            id: 'objectsWrapper.columnVisibilityControls.buttonTitle',
          })}
        </span>
        <EyeIcon />
      </div>
      <div
        className={classNames('ColumnVisibilityControls_popover', {
          'ColumnVisibilityControls_popover__open': isOpen,
        })}>
        <MultiCheckSelect
          style={{ margin: theme.verticalMargin }}
          items={columnVisibilityOptions}
          titlePropPath={['label']}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
export default ColumnVisibilityControls;
