import { useContext, useEffect, useMemo } from 'react';
import { ProjectContext } from '../../projects/contexts';
import { useCementoRouter } from '../../../web/components/Router/util/withRouterHOC';
import ObjectsWrapperView from '../ObjectsWrapperView';
import PostCard from '../views/grid/components/PostCard';
import { preProcessInstances } from '../utils';
import { useSelector } from 'react-redux';
import { lokiInstance } from '../../configureMiddleware';
import useSavedMenus from '../../../web/components/Header/useSavedMenus';
import _ from 'lodash';
import { fillBusinessType } from '../../propertiesTypes/funcs';
import useCompanies from '../../companies/hooks/useCompanies';
import DataManagerInstance from '../../dataManager/DataManager';
import { SUBJECTS } from '../../dataManager/subjects';
import useIntl from '../../intl/useIntl';
import { getAllLocationTitlesMap } from '../../../web/views/Locations/funcs';
import { getPropertiesTypesOfPostsInfo } from '../../propertiesInstances/funcs';
import { DATE, MOCK_PROPERTY_TYPES, STRING } from '../../propertiesTypes/propertiesTypes';
import { decodeFiltersFromSearch, encodeFilterToSearch } from '../../../web/app/funcs';
import { FILTER_MENU_PATH_DELIMETER, FILTER_URL_KEY } from '../../../web/app/constants';
import { getLocationsObjectsForTable } from '../../../web/views/Reports/funcs';
import systemMessages from '../../app/systemMessages';
import analyticsMessages from '../../analytics/analyticsMessages';
import { loadingSubjectsMapper } from '../../../web/views/Properties/PropertyAnalytics';
import { useLoading } from '../../hooks/useLoading';

const formType = 'dailyReport';

const InstancesPreProcessorHOC = (WrappedComponent) => {
  const WithInstances = ({ contentType, section }) => {
    patchNextGenLocationSearchIfNeeded();

    const intl = useIntl();
    const { selectedFav } = useSavedMenus({
      contentType,
      section,
    });
    const projectProps = useContext(ProjectContext);

    const { selectedProjectId, viewer, buildings, floors, units, propertiesSections } = projectProps;
    const subjectType = selectedFav?.subjectType;
    const subjectName = `${subjectType}Info`;

    useEffect(() => {
      connectToSubjects({ selectedProjectId, viewer, subjectType, formType });
    }, [selectedProjectId, subjectType]);

    const outletProps = useCementoRouter();

    const { companies } = useCompanies();

    const { trades, members, subCategories, propertiesTypes } = useSelector((state) => ({
      trades: state.trades.map,
      subCategories: state.quasiStatics.subCategoriesMap,
      members: state.members.map,
      propertiesTypes: state.propertiesTypes.projectProperties?.[projectProps.selectedProjectId],
    }));
    const { objectValues, fakePrimaryProp } = getObjects({
      posts: outletProps.currViewPosts,
      subjectType,
      selectedProjectId,
      buildings,
      floors,
      units,
      intl,
      formType,
      subjectName,
    });
    const richPropTypes = useMemo(() => {
      let rawPropTypes = propertiesTypes?.[subjectName];
      let rawPropertiesSections = propertiesSections?.[subjectName];

      if (subjectType == 'posts') {
        rawPropTypes = getPropertiesTypesOfPostsInfo({
          postsInfo: _.merge(propertiesTypes?.postsInfo, MOCK_PROPERTY_TYPES.postsInfo),
          contentType: contentType,
        });
      }
      return rawPropTypes
        ? Object.entries(rawPropTypes).reduce(
            (acc, [key, prop]) => {
              let newProp = prop;
              if (prop.businessType) {
                newProp = fillBusinessType(prop, {
                  trades,
                  companies,
                  members,
                  subCategories,
                });
              }
              if (rawPropertiesSections && prop.sectionId) {
                newProp.section = rawPropertiesSections[prop.sectionId];
              }
              acc[key] = newProp;
              return acc;
            },
            fakePrimaryProp?.id
              ? {
                  [fakePrimaryProp.id]: fakePrimaryProp,
                }
              : {}
          )
        : rawPropTypes;
    }, [propertiesSections, trades, members, subCategories, propertiesTypes]);

    // useEffect(() => {
    //   const lokiPropertyInstances = lokiInstance.getCollection('propertyInstances');
    //   const listener = () => {
    //     const res = lokiPropertyInstances.cementoFind({
    //       'projectId': projectProps.selectedProjectId,
    //       subjectName,
    //     });
    //   };
    //   lokiPropertyInstances.cementoOn('lokiInstancesObjectAnalytics', listener);
    //   return () => {
    //     lokiPropertyInstances.cementoOff('lokiInstancesObjectAnalytics', listener);
    //   };
    // }, []);
    const subjectPaths = useMemo(() => {
      return loadingSubjectsMapper(subjectType, projectProps.selectedProjectId);
    }, [subjectType]);

    const { isLoading } = useLoading(subjectPaths);
    const propertiesInstances = useMemo(() => {
      const lokiPropertyInstances = lokiInstance.getCollection('propertyInstances');
      // this.lokiPropertyInstances.cementoOn('lokiInstancesObjectAnalytics', this.lokiListener);

      const res = lokiPropertyInstances.cementoFind({
        'projectId': projectProps.selectedProjectId,
        subjectName,
      });

      return res;
    }, [projectProps.selectedProjectId, subjectName, isLoading]);

    const locationsTitlesMap = useMemo(() => {
      return getAllLocationTitlesMap(buildings, floors, units, intl);
    }, [buildings, floors, units]);

    const objects = usePreProcessedInstances({
      objects: objectValues,
      instances: propertiesInstances,
      propTypes: richPropTypes,
      locationsTitlesMap,
      members,
    });

    const shouldRender = !!(objects && richPropTypes);
    // TODO INCLUDE INTO RICH PROP TYPES
    const columnVisibility = selectedFav?.values?.columnVisibility;

    if (!shouldRender) return null;

    return (
      <WrappedComponent
        // TODO
        subjectType={subjectType}
        contentType={contentType}
        propertiesInstances={propertiesInstances}
        //
        objects={objects}
        propTypes={richPropTypes}
        columnVisibility={columnVisibility}>
        <ObjectsWrapperView
          contentType={contentType}
          selectedProjectId={projectProps.selectedProjectId}
          ItemComponent={PostCard}
        />
      </WrappedComponent>
    );
  };
  return WithInstances;
};

export default InstancesPreProcessorHOC;

const getObjects = ({ posts, subjectType, selectedProjectId, buildings, floors, units, formType, intl }) => {
  let objectValues = [];
  let fakePrimaryProp = null;

  if (['employees', 'equipment'].includes(subjectType)) {
    objectValues = lokiInstance.getCollection(subjectType)
      ? lokiInstance.getCollection(subjectType).cementoFind({ 'projectId': selectedProjectId })
      : [];
  } else if (subjectType === 'forms') {
    objectValues = lokiInstance.getCollection(subjectType)
      ? lokiInstance.getCollection(subjectType).cementoFind({ 'projectId': selectedProjectId, 'type': formType })
      : [];
  } else if (subjectType === 'locations') {
    objectValues = getLocationsObjectsForTable(buildings, floors, units, intl);
  } else {
    objectValues = posts;
  }
  if (subjectType === 'forms') {
    fakePrimaryProp = {
      id: `-formDate-${subjectType}Table`,
      settings: { dateFormat: intl.formatMessage(systemMessages.fullDateFormat), timezone: 'Asia/Jerusalem' },
      title: intl.formatMessage(analyticsMessages.dashboards.axesLabels.date),
      type: DATE,
      pathInObject: 'reportDate',
      ordinalNo: 99999, // Needs ridiculous ordinalNo because we are assigning it a random sectionId and, in the case that this column comes first in the processing, we want its ordinalNo to be overwritten by a lower one from a real property in the section
    };
  } else if (subjectType === 'locations') {
    fakePrimaryProp = {
      id: `-locationName-${subjectType}Table`,
      title: intl.formatMessage(analyticsMessages.location),
      type: STRING,
      pathInObject: 'title',
      ordinalNo: 99999, // Needs ridiculous ordinalNo because we are assigning it a random sectionId and, in the case that this column comes first in the processing, we want its ordinalNo to be overwritten by a lower one from a real property in the section
    };
  }

  return { objectValues: Object.values(objectValues), fakePrimaryProp };
};

const usePreProcessedInstances = ({ objects, instances, propTypes, locationsTitlesMap, members }) => {
  return useMemo(() => {
    const preProcessedInstances = preProcessInstances({ objects, instances, propTypes, locationsTitlesMap, members });
    return preProcessedInstances;
  }, [objects, instances, propTypes]);
};

const connectToSubjects = ({ selectedProjectId, viewer, subjectType, formType }) => {
  const basicParams = {
    scope: 'projects',
    scopeId: selectedProjectId,
    viewer,
  };

  DataManagerInstance.loadAndConnect({
    ...basicParams,
    subject: SUBJECTS.PROPERTIES_INSTANCES,
    queryParams: { subjectName: `${subjectType}Info` },
  });

  let extraParams = { subject: subjectType };
  if (subjectType === SUBJECTS.FORMS) {
    DataManagerInstance.loadAndConnect({
      ...basicParams,
      subject: SUBJECTS.PROPERTIES_INSTANCES,
      queryParams: { subjectName: `${SUBJECTS.POSTS}Info` },
    });
    DataManagerInstance.loadAndConnect({ ...basicParams, subject: SUBJECTS.POSTS });
    extraParams.queryParams = { formType };
  }

  if (subjectType !== 'locations') DataManagerInstance.loadAndConnect({ ...basicParams, ...extraParams });
};

const patchNextGenLocationSearchIfNeeded = () => {
  const decodedFilter = decodeFiltersFromSearch(location.search, FILTER_URL_KEY, FILTER_MENU_PATH_DELIMETER);

  if (!location.search.includes(FILTER_MENU_PATH_DELIMETER)) return;

  const filterObject = Object.entries(decodedFilter.originalFilterObj).reduce((acc, [path, value]) => {
    const parsedPath = path
      .replaceAll(FILTER_MENU_PATH_DELIMETER, '')
      .replaceAll('values', '')
      .replaceAll('originalValue', '');

    _.set(acc, parsedPath, value);
    return acc;
  }, {});

  const newSearch = encodeFilterToSearch(filterObject, '', FILTER_URL_KEY);
  location.search = newSearch;
};
