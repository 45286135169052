import { useEffect, useState } from 'react';
import postsMenuMessages from '../../posts/postsMenuMessages';
import analyticsMessages from '../../analytics/analyticsMessages';
import newPostMessages from '../../posts/newPostMessages';
import useIntl from '../../intl/useIntl';
import _ from 'lodash';
import { LOCATION_COLUMNS, VIEW_TYPES } from '../const';
import { getCementoStringValue } from '../utils';
import useSelectedObject from './useSelectedObject';

const useObjectsManipulations = ({
  objects,
  smartObjects,
  propTypes,
  columnVisibility,
  subjectType,
  contentType,
  propertiesInstances,
}) => {
  const intl = useIntl();
  const [filters, setFilters] = useState({
    newSearch: location.search,
  });
  const [groupBy, setGroupBy] = useState(['trade', 'assignTo']);
  const [sortBy, setSortBy] = useState({ column: 'trade', direction: 'asc' });
  const [search, setSearch] = useState(null);
  const [viewType, setViewType] = useState(VIEW_TYPES.GRID);
  const [columnDefinitions, setColumnDefinitions] = useState([]);
  const [groupByOptions, _setGroupByOptions] = useState([]);
  const [filterOptions, _setFilterOptions] = useState({});
  const [skip, setSkip] = useState(null);
  const [step, setStep] = useState(null);
  // Derive unique groupByOptions and filterOptions from object properties
  const deriveColumns = (propTypes) => {
    const columns = Object.keys(propTypes)
      .sort((a, b) => propTypes[a].ordinalNo - propTypes[b].ordinalNo)
      .map((key) => {
        const prop = propTypes[key];
        const column = {
          key,
          title: prop?.title,
          ordinalNo: prop?.ordinalNo,
          section: prop?.section,
          type: prop?.type,
          isVisible: columnVisibility ? columnVisibility?.[key]?.table ?? false : true,
          width: 125 * (prop?.settings?.widthRatio || 1),
          isPrimary: prop?.isPrimary,
        };
        return column;
      });

    const columnDefinitions = [
      ...columns,
      ...LOCATION_COLUMNS.map((col) => ({ ...col, title: intl.formatMessage(col.title) })),
    ];

    setColumnDefinitions(columnDefinitions);
    return columnDefinitions;
  };

  useEffect(() => {
    deriveColumns(propTypes);
  }, [propTypes]);

  useEffect(() => {
    const { groupByOptions, filterOptions } = calculateOptions({
      objects: smartObjects,
      columnDefinitions,
      propTypes,
      intl,
    });
    _setGroupByOptions(groupByOptions);
    _setFilterOptions(filterOptions);
  }, [smartObjects, columnDefinitions]);

  const { selectedObject, setSelectedObject, createNewObject } = useSelectedObject({
    subjectType,
    contentType,
    propertiesInstances,
    columnDefinitions,
    filters,
    objects,
  });

  return {
    // Values
    selectedObject,
    groupByOptions,
    filterOptions,
    filters,
    columnDefinitions,
    groupBy,
    sortBy,
    search,
    viewType,
    skip,
    step,
    subjectType,
    contentType,
    // Methods
    setSelectedObject,
    createNewObject,
    setFilters,
    setColumnDefinitions,
    setGroupBy,
    setSortBy,
    setSearch,
    setViewType,
    setSkip,
    setStep,
  };
};

const getLegacyGroupByOptions = ({ columnDefinitions, intl }) => {
  let menu = [
    { title: postsMenuMessages['trade'], key: 'trade', ordinalNo: 1 },
    {
      title: `${intl.formatMessage(postsMenuMessages.owner)} - ${intl.formatMessage(
        analyticsMessages.viewType.filterByUser
      )}`,
      key: 'owner',
      ordinlaNo: 6,
    },
    { title: postsMenuMessages['createdAt'], key: 'createdAt', ordinalNo: 7 },
    { title: postsMenuMessages['floor'], key: 'floor', ordinalNo: 9 },
    { title: postsMenuMessages['unit'], key: 'unit', ordinalNo: 10 },
  ];

  // if (contentType === 'safety') {
  menu.push({ title: newPostMessages.subCategory, key: 'subCategory', ordinalNo: 2 });
  // }

  // if (isIssuesPage) {
  menu.push(
    {
      title: `${intl.formatMessage(postsMenuMessages.assignTo)} - ${intl.formatMessage(postsMenuMessages.company)}`,
      key: 'assignToCompany',
      ordinalNo: 5,
    },
    {
      title: `${intl.formatMessage(postsMenuMessages.owner)} - ${intl.formatMessage(
        analyticsMessages.viewType.filterByCompany
      )}`,
      key: 'ownerCompany',
      ordinlaNo: 6.5,
    }
  );
  menu.push(
    { title: postsMenuMessages['issueState'], key: 'issueState', ordinalNo: 3 },
    {
      title: `${intl.formatMessage(postsMenuMessages.assignTo)} - ${intl.formatMessage(
        analyticsMessages.viewType.filterByUser
      )}`,
      key: 'assignTo',
      ordinalNo: 4,
    },

    { title: postsMenuMessages['dueDate'], key: 'dueDate', ordinalNo: 8 }
  );

  return menu.filter((item) => columnDefinitions.find((col) => col.isVisible && col.key === item.key));
};

const calculateOptions = ({ objects, columnDefinitions, propTypes, intl }) => {
  const groupByOptions = getLegacyGroupByOptions({ columnDefinitions, intl });
  const filterOptions = {};

  objects.forEach((obj) => {
    Object.entries(propTypes).forEach(([propName, propData]) => {
      if (!propData) return;

      let value = _.get(obj, propData.id);
      const propTitle = propData.getCementoTitle()
        ? propData.getCementoTitle()
        : propData.title?.id
        ? intl.formatMessage(propData.title)
        : propData.title?.en;

      if (propTitle) {
        if (!filterOptions[propName]) {
          filterOptions[propName] = {
            id: propData.id,
            type: propData.type === 'String' ? 'SelectionList' : propData.type,
            inputSettings: propData.settings,
            ordinalNumber: propData.ordinalNumber,
            title: propTitle,
            options: [],
          };
        }
        if (value) {
          let id = value?.cementoValue?.id || value?.id || value;
          let title = getCementoStringValue(value);
          if (title) filterOptions[propName].options.push({ id, title });
        }
      }
    });
  });

  return {
    groupByOptions,
    filterOptions: [
      {
        id: 'defaultView',
        title: 'defaultView',
        categories: Object.values(filterOptions),
      },
    ],
  };
};

export default useObjectsManipulations;
