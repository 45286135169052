import _ from 'lodash';
import { instanceDataToString } from '../propertiesInstances/funcs';

/**
 *
 * @param {({ id: string, updatedTS: number } & Record<string, any>)[]} objects
 * @param {{ id: string, updatedTS: number, data: any, propId: string, propType: string }[]} instances
 * @param {{ id: string, type: string, universalId: string } & Record<string, any>} propTypes
 * @returns ({ updatedTS: number, id: string } & Record<string, any>)[]
 */

export const preProcessInstances = ({ objects, instances, propTypes, locationsTitlesMap, members }) => {
  if (!objects?.length || !propTypes) {
    return [];
  }

  const instancesByParentId = _.groupBy(instances, 'parentId');
  const processedObjects = _.reduce(
    objects,
    (acc, object = {}) => {
      const instances = instancesByParentId?.[object.id] || [];
      let nextGenInstance = _.cloneDeep(object);

      attachLocationsTitles(nextGenInstance, locationsTitlesMap);

      const ownerMember = _.pick(members?.[nextGenInstance.owner?.id], ['id', 'displayName', 'companyId']);
      const assignToMember = _.pick(members?.[nextGenInstance.assignTo?.id], ['id', 'displayName', 'companyId']);
      const owner = _.merge(ownerMember, nextGenInstance.owner);
      const assignTo = _.merge(assignToMember, nextGenInstance.assignTo);

      _.set(nextGenInstance, 'props', _.keyBy(propTypes, 'id'));
      _.set(nextGenInstance, 'owner', owner);
      _.set(nextGenInstance, 'assignTo', assignTo);

      instances.forEach(({ data, propId }) => {
        _.set(nextGenInstance, propId, data);
      });

      acc.push(nextGenInstance);
      return acc;
    },
    []
  );

  return processedObjects;
};

const attachLocationsTitles = (object, locationsTitlesMap) => {
  if (!object?.location || !locationsTitlesMap) {
    return;
  }
  Object.keys(object.location).forEach((key) => {
    const valueId = object.location[key]?.id;
    if (valueId) {
      object[key] = {
        id: valueId,
        cementoValue: {
          id: valueId,
          value: valueId,
          title: locationsTitlesMap[valueId]?.[`${key}Title`],
        },
      };
    }
  });

  return object;
};

export const getNextGenValue = (target, propData, intl) => {
  const fullProp = _.get(target, propData.id);
  const propValue = _.get(target, propData.universalId);
  if (fullProp?.cementoValue) return fullProp;

  let nextGen = null;

  if (propData.values) {
    const richValue = propData.values?.find?.(
      (v) => v.id == propValue || (fullProp && v.id === Object.values(fullProp)[0])
    );
    nextGen = {
      id: richValue?.id,
      cementoValue: richValue,
    };
  } else {
    nextGen = {
      id: fullProp?.id,
      cementoValue: {
        id: fullProp?.id,
        value: fullProp,
        title: instanceDataToString(propData, propValue || fullProp, intl),
      },
    };
  }

  return nextGen;
};

export const getCementoStringValue = (nextGenValue) => {
  if (!nextGenValue) return nextGenValue;
  if (nextGenValue?.cementoValue) {
    return nextGenValue.cementoValue.getCementoTitle();
  }
  return typeof nextGenValue === 'object' ? nextGenValue?.getCementoTitle?.() : nextGenValue;
};
