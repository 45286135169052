import { useContext, useState, useMemo, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { DndContext, MouseSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import _ from 'lodash';

// Cemento imports
import { ObjectsWrapperDataContext, ObjectsWrapperManipulationContext } from '../../../ObjectsWrapper';
import DraggableTableHeader from './components/DraggableTableHeader';
import { fuzzyFilter, getData } from './util';
import ExpandableTableHeader from './components/ExpandableTableHeader';
import ExpandableTableCell from './components/ExpandableTableCell';
import useIntl from '../../../../intl/useIntl';
import useGroupBy from '../../../hooks/useGroupBy';
import CustomCell from './components/CustomCells';
import { getCementoStringValue } from '../../../utils';
import { useCementoRouter } from '../../../../../web/components/Router/util/withRouterHOC';

const ObjectsWrapperListViewTanStackTable = () => {
  const router = useCementoRouter();
  const virtualisationOverscan = router?.match?.params?.queryParams?.overscan || 200;

  const intl = useIntl();
  const parentRef = useRef(null);
  const [expanded, setExpanded] = useState({});
  const [globalFilter, setGlobalFilter] = useState('');
  const { data: rawData } = useContext(ObjectsWrapperDataContext);
  const { groupBy, sortBy, columnDefinitions, setColumnDefinitions, selectedObject, setSelectedObject } = useContext(
    ObjectsWrapperManipulationContext
  );
  const isRTL = useSelector((state) => state.app.rtl);
  const groups = useGroupBy(rawData, groupBy);

  useEffect(() => {
    setExpanded({});
  }, [groupBy, sortBy]);

  const [columnOrder, setColumnOrder] = useState([]);

  const { rowData, columns } = useMemo(() => {
    const rowData = getData({ data: groups, groupBy, columnDefinitions });

    const headerGroups = _.groupBy(
      columnDefinitions.sort((columnDefinition) => {
        return groupBy[0]?.column === columnDefinition.key;
      }),
      'section.id'
    );

    const columns = Object.entries(headerGroups).reduce((acc, [groupKey, columnDefinitions]) => {
      const nestedColumns = columnDefinitions.reduce((acc, columnDefinition) => {
        const columnKey = columnDefinition.key;
        const groupByIndex = groupBy.indexOf(columnKey) + 1;

        const title = columnDefinition?.title?.id
          ? intl.formatMessage(columnDefinition.title)
          : columnDefinition?.getCementoTitle() ?? columnKey;

        let column = {
          id: columnKey,
          title,
          accessorFn: (row) => getCementoStringValue(row[columnKey]),
          filterFn: 'includesString', //note: normal non-fuzzy filter column - case insensitive
          header: title,
          footer: () => columnKey,
          groupKey,
          size: 150, //columnDefinition.width,
          enableResizing: true,
          visibility: columnDefinition.isVisible,
          ordinalNo: columnDefinition.ordinalNo,
          cementoType: columnDefinition.type,
        };

        if (groupByIndex === 1) {
          column.filterFn = 'fuzzy';
          column.header = ({ table }) => <ExpandableTableHeader table={table} title={title} />;
          column.cell = ({ cell, getValue }) => <ExpandableTableCell cell={cell} getValue={getValue} />;
          column.isGroupBy = true;
          column.groupByIndex = groupBy.indexOf(columnKey);
          column.pinned = groupByIndex === 1 ? (isRTL ? 'right' : 'left') : null;
          acc.unshift(column);
        } else if (!groupByIndex) {
          acc.push(column);
        }

        return acc;
      }, []);
      const groupTitle = columnDefinitions[0]?.section?.title?.id
        ? intl.formatMessage(columnDefinitions[0]?.section?.title)
        : columnDefinitions[0]?.section?.getCementoTitle() ?? '-';

      acc.push({
        id: groupKey,
        accessorKey: groupKey,
        filterFn: 'fuzzy',
        header: groupTitle,
        footer: () => groupKey,
        visibility: true,
        columns: nestedColumns,
        isHeaderGroup: true,
      });

      return acc;
    }, []);

    // Preserve column order
    if (!columnOrder.length) {
      const columnOrder = columns?.reduce((acc, column) => {
        column?.columns?.forEach((subColumn) => {
          acc.push(subColumn.id);
        });
        acc.push(column.id);
        return acc;
      }, []);

      setColumnOrder(columnOrder);
    }

    return {
      rowData,
      columns,
    };
  }, [groups, groupBy, sortBy, expanded]);

  const { columnVisibility, setColumnVisibility } = useMemo(() => {
    const columnVisibility = columnDefinitions.reduce((acc, c) => {
      acc[c.key] = c.isVisible;
      return acc;
    }, {});

    const setColumnVisibility = ({ columnId, value }) => {
      setColumnDefinitions(
        columnDefinitions.map((columnDefinition) => {
          if (columnDefinition.key === columnId) {
            return {
              ...columnDefinition,
              isVisible: value,
            };
          }
          return columnDefinition;
        })
      );
    };
    return { columnVisibility, setColumnVisibility };
  }, [columnDefinitions, setColumnDefinitions]);

  const table = useReactTable({
    data: rowData,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
    },
    state: {
      expanded: !groupBy?.length ? true : expanded,
      columnOrder,
      columnVisibility,
      globalFilter,
    },
    defaultColumn: {
      size: 250,
      minSize: 100,
      maxSize: 500,
    },
    columnResizeMode: 'onChange',
    columnResizeDirection: isRTL ? 'rtl' : 'ltr',
    globalFilterFn: 'includesString',
    onGlobalFilterChange: setGlobalFilter,
    onExpandedChange: setExpanded,
    onColumnVisibilityChange: setColumnVisibility,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onColumnOrderChange: setColumnOrder,
    filterFromLeafRows: true,
  });

  /**
   * Instead of calling `column.getSize()` on every render for every header
   * and especially every data cell (very expensive),
   * we will calculate all column sizes at once at the root table level in a useMemo
   * and pass the column sizes down as CSS variables to the <table> element.
   */
  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i];
      colSizes[`--header-${header.id}-size`] = header.getSize();
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
    }
    return colSizes;
  }, [table.getState().columnSizingInfo, table.getState().columnSizing]);

  // reorder columns after drag & drop
  function handleDragEnd(event) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id);
        const newIndex = columnOrder.indexOf(over.id);
        return arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
      });
    }
  }

  const sensors = useSensors(useSensor(MouseSensor, {}));

  const rowModel = table.getRowModel();
  const { rows } = rowModel;

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 40,
    overscan: virtualisationOverscan,
  });

  const onRowClick = (row) => {
    if (row.original.isGroup) return;
    return setSelectedObject({ objectId: row.original.id });
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}>
      <div ref={parentRef} className='ObjectsWrapperListViewTanStackTable_root height-without-header p-2'>
        <table
          className='ObjectsWrapperListViewTanStackTable_table AnalyticsTable innerTable'
          style={{
            ...columnSizeVars,
            height: `${virtualizer.getTotalSize()}px`,
            width: table.getTotalSize(),
          }}>
          <thead className='ObjectsWrapperListViewTanStackTable_table_thead'>
            {table.getHeaderGroups().map((headerGroup, headerGroupIndex) => {
              return (
                <tr key={headerGroup.id}>
                  <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
                    {headerGroup.headers.map((header) => {
                      const isFirst = groupBy.includes(header.id);
                      return (
                        <DraggableTableHeader
                          key={header.id}
                          headerGroupIndex={headerGroupIndex}
                          header={header}
                          table={table}
                          className={`TableData ObjectsWrapperListViewTanStackTable_table_cell ObjectsWrapperListViewTanStackTable_table_cell__th ${
                            isFirst ? 'FirstColumn' : ''
                          }`}
                        />
                      );
                    })}
                  </SortableContext>
                </tr>
              );
            })}
          </thead>
          <tbody className='ObjectsWrapperListViewTanStackTable_table_tbody'>
            {virtualizer.getVirtualItems().map((virtualRow, index) => {
              const row = rows[virtualRow.index];
              const isGrouped = row.depth === 0;
              const isFirstGroup = isGrouped && index === 0;
              return (
                <>
                  {isGrouped && !isFirstGroup ? (
                    <tr className='ObjectsWrapperListViewTanStackTable_table_tr_spacer' />
                  ) : null}
                  <tr
                    className={classNames(
                      'ObjectsWrapperListViewTanStackTable_table_tr tableRowBackground tableHoverBackground',
                      {
                        'ObjectsWrapperListViewTanStackTable_table_tr__active':
                          selectedObject?.id && selectedObject?.id === row.original.id,
                      }
                    )}
                    style={{
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                    }}
                    onClick={() => onRowClick(row)}
                    key={virtualRow.key}>
                    {row.getVisibleCells().map((cell) => {
                      const isFirst = groupBy.includes(cell.column.id);
                      return (
                        <SortableContext key={cell.id} items={columnOrder} strategy={horizontalListSortingStrategy}>
                          <CustomCell
                            key={cell.id}
                            cell={cell}
                            className={`TableData ObjectsWrapperListViewTanStackTable_table_cell ObjectsWrapperListViewTanStackTable_table_cell__td ${
                              isFirst ? 'FirstColumn' : ''
                            }`}
                          />
                        </SortableContext>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </DndContext>
  );
};

export default ObjectsWrapperListViewTanStackTable;
